<template>
  <div>
    <v-container >
      <CRUDList
        :canAdd="true"
        :canEdit="true"
        :canSave="true"
        :headers="headers"
        :baseCrudKey="baseCrudKey"
        toolbarTitle="Tax District"
        @loadCRUDList="handleLoadCRUDList"
        @upsertItem="upsrtTransaction"
        :defaultSortBy="sortBy"
      >
        <template v-slot:[`editedItem`]="{}">
          <taxDistrictEditedItem
            :baseCrudKey="baseCrudKey"
          />
        </template>
      </CRUDList>
    </v-container>
  </div>
</template>

<script>
import {
  mapGetters,
  mapActions,
  mapMutations,
} from 'vuex';
import CRUDList from '../../common/base/crud/CRUDList.vue';
import taxDistrictEditedItem from './taxDistrictEditedItem.vue';
import {
  extractId,
} from '../../../util/shared/vue-global';
import {
  toFiscalYear,
} from '../../../util/shared/tmc-global';

const getHeaders = () => {
  const headers = [
    { text: 'SD', value: 'districtCode' },
    { text: 'Name', value: 'districtName' },
    { text: 'To Unit', value: 'taxUnit.toUnitDescForAppro' },
    { text: 'Joint', value: 'isJointCounty', formatter: 'boolToStr' },
    { text: 'Remit', value: 'isRemitFund', formatter: 'boolToStr' },
    { text: 'ADA', value: 'ada' },
  ];
  return headers;
};

/* As per business 'B' section not represent ADA it represent ABT and MV
  So on UI we are skipping 'averageDailyAttendence' values for ADA.
  And isRequiredAdaForCalculation flag is also deciding to display ADA.
*/
const getAdaFromTaxUnitRecord = (record) => ((record.taxUnit && record.taxUnit.appSection !== 'B') && record.isRequiredAdaForCalculation ? record.taxUnit.averageDailyAttendence : null);

const postProcessTaxDistrict = (records) => {
  const updatedRecords = [];
  records.forEach((record) => {
    const updatedRecord = { ...record };
    updatedRecord.ada = getAdaFromTaxUnitRecord(updatedRecord);
    updatedRecords.push(updatedRecord);
  });
  return updatedRecords;
};

export default {
  name: 'taxDistrictList',
  components: {
    CRUDList,
    taxDistrictEditedItem,
  },
  data: () => ({
    baseCrudKey: 'sharedTaxDistrict',
    headers: [],
    sortBy: [
      {
        column: 'districtCode',
        direction: 'desc',
      },
    ],
  }),
  created() {
    this.headers = getHeaders();
    this.initCriteria();
    this.loadLists();
  },
  computed: {
    ...mapGetters('base/crud', [
      'criteria',
      'index',
      'item',
    ]),
    ...mapGetters(['todaysDate']),
    criteriaState: {
      get() {
        return this.criteria(this.baseCrudKey);
      },
      set(value) {
        this.setCriteria([this.baseCrudKey, value]);
      },
    },
    currentFiscalYear() {
      return toFiscalYear(new Date(this.todaysDate));
    },
  },
  methods: {
    ...mapActions('shared/taxDistrict', [
      'loadTaxDistrict',
      'upsertTaxDistrict',
    ]),
    ...mapActions('shared/taxUnit', [
      'loadTaxUnit',
    ]),
    ...mapMutations('base/crud', [
      'setCriteria',
    ]),
    initCriteria() {
      // reset criteria while merge flag is true
      this.criteriaState = {};
    },
    handleLoadCRUDList() {
      const { baseCrudKey } = this;
      const criteria = this.criteriaState;
      this.loadTaxDistrict({
        criteria,
        baseCrudKey,
        postProcessTaxDistrict,
      });
    },
    async upsrtTransaction(item) {
      const { baseCrudKey } = this;
      const updatedItem = { ...item };
      delete updatedItem.ada;
      if (updatedItem.taxUnit) {
        updatedItem.taxUnit = extractId(updatedItem.taxUnit);
      }
      await this.upsertTaxDistrict({ item: updatedItem, baseCrudKey });
    },
    newItemDialog() {
      let newItem = {};
      newItem = {
        districtCode: '',
        districtName: '',
        isJointCounty: '',
        isRemitFund: '',
        taxUnit: null,
      };
      return newItem;
    },
    async loadLists() {
      await this.loadTaxUnit({
        criteria: {
          fiscalYear: this.currentFiscalYear,
        },
        baseCrudKey: 'sharedTaxUnit',
      });
    },
  },
};

</script>
