<template>
   <v-container>
    <v-row>
      <v-col cols="3">
        <v-text-field id="taxDistrictEditDialogSchoolDistrictText"
          class="tax-district-edit-dialog-school-district-text"
          v-model="editedItem.districtCode"
          label="School District"
          @blur="somethingChanged"
        >
        </v-text-field>
      </v-col>
      <v-col cols="3">
        <v-text-field id="taxDistrictEditDialogDistrictNameText"
          class="tax-district-edit-dialog-district-name-text"
          v-model="editedItem.districtName"
          label="Name"
          @blur="somethingChanged"
        >
        </v-text-field>
      </v-col>
      <v-col cols="3">
        <v-select id="taxDistrictEditDialogToUnitSelect"
          class="tax-district-edit-dialog-to-unit-select"
          label="To Unit"
          v-model="editedItem.taxUnit"
          :items="toUnitDescList"
          item-text="toUnitDesc"
          item-value="_id"
          @blur="somethingChanged"
        >
        </v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3">
        <v-checkbox id="taxDistrictEditDialogRemitFundsCheckbox"
          class="tax-district-edit-dialog-remit-funds-checkbox"
          v-model="editedItem.isRemitFund"
          label="Remit Funds"
          @blur="somethingChanged"
        ></v-checkbox>
      </v-col>
      <v-col cols="3">
        <v-checkbox id="taxDistrictEditDialogJointCountyCheckbox"
          class="tax-district-edit-dialog-joint-county-checkbox"
          v-model="editedItem.isJointCounty"
          label="Joint County"
          @blur="somethingChanged"
        ></v-checkbox>
      </v-col>
      <v-col cols="3">
        <v-checkbox id="taxDistrictEditDialogAdaForCalculationFlagCheckbox"
          class="tax-district-edit-dialog-ada-for-calculation-flag-checkbox"
          v-model="editedItem.isRequiredAdaForCalculation"
          label="Required ADA for calculation"
          @blur="somethingChanged"
        ></v-checkbox>
      </v-col>
      <v-col cols="3">
        <v-text-field
          :value="editedItem.ada"
          label="ADA"
          readonly
          ></v-text-field>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {
  mapGetters,
  mapMutations,
  mapState,
} from 'vuex';

export default {
  name: 'taxDistrictEditedItem',
  components: {
  },
  data: () => ({
    taxUnitCrudKey: 'sharedTaxUnit',
  }),
  props: {
    baseCrudKey: {
      type: String,
      default: 'taxDistrict-dialog',
    },
  },
  created() {
  },
  computed: {
    ...mapGetters('base/crud', [
      'item',
      'items',
      'index',
      'isVisible',
    ]),
    ...mapState({
    }),
    editedItem: {
      get() {
        return this.item(this.baseCrudKey);
      },
      set(value) {
        this.setItem([this.baseCrudKey, value]);
      },
    },
    toUnitDescList() {
      const unitDescObj = this.items(this.taxUnitCrudKey) || [];
      const updatedUnitDescObj = unitDescObj.map((u) => ({
        ...u,
        toUnitDesc: `${u.toUnitDescForAppro || ''}  ${u.description || ''}`,
      }));
      return [{ _id: null, toUnitDescForAppro: '', toUnitDesc: '' }, ...updatedUnitDescObj];
    },
  },
  methods: {
    ...mapMutations('base/crud', [
      'setItem',
    ]),
    somethingChanged() { // Is this required?
      this.$emit('input', this.editedItem);
    },
  },
};
</script>
