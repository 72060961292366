<template>
  <v-container>
    <taxDistrictList/>
  </v-container>
</template>

<script>

import taxDistrictList from '../../components/shared/taxDistrict/taxDistrictList.vue';

export default {
  name: 'TaxDistrict',
  components: {
    taxDistrictList,
  },
};

</script>
